// src/App.js
import React, { useState } from 'react';
import './App.css';

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username === 'user' && password === 'pass') {
      setMessage('Authentication successful!');
      window.location.href = 'http://www.example.com';
    } else {
      setMessage('Invalid credentials. Please try again.');
    }
  };

  return (
    <div className="portal-container">
      <div className="portal-content">
        <h1>Welcome to Our Network</h1>
        <p>Please sign in to access the internet</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Sign In</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default App;
